import cx from 'classnames'

import { type SanityLogoGridModule } from '@data/sanity/queries/types/modules'

import Photo from '@components/photo'

type LogoGridProps = Pick<
  SanityLogoGridModule,
  'title' | 'description' | 'logos'
>

const LogoGrid = ({ title, description, logos }: LogoGridProps) => {
  return (
    <section className="container py-10 md:py-16">
      {!!title && (
        <h5 className={cx('text-center', { 'mb-5': !description })}>{title}</h5>
      )}
      {!!description && (
        <p className="text-center max-w-3xl mb-5 mx-auto pt-2">{description}</p>
      )}
      {!!logos && (
        <div className="flex justify-center">
          <div
            className={cx(
              'grid grid-rows-[7rem] gap-2',
              'grid-cols-[repeat(2,minmax(5rem,13rem))]',
              'sm:grid-cols-[repeat(3,minmax(5rem,13rem))]',
              'md:grid-cols-[repeat(4,minmax(5rem,13rem))]',
              'lg:grid-cols-[repeat(6,minmax(5rem,13rem))]'
            )}
          >
            {logos.map((logo) => (
              <div
                key={logo._key}
                className="flex items-center justify-center p-4 w-full border border-gray-200"
              >
                <Photo
                  image={logo}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

export default LogoGrid
